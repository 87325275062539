
import React, { Component, useEffect, useRef  } from 'react';
import Form from "react-bootstrap/Form";
import firebase from "../firebase.js";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import Resizer from "react-image-file-resizer";
import ReactToPrint from "react-to-print";
import stomatologLogo from "../stomlogo.png";
import logoa4 from "../logoa4.png";
import "moment/locale/sr";
import { DatePickerRC, DatePickerInput } from "rc-datepicker";
import "./myStyles.css";
import "rc-datepicker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-medium-image-zoom/dist/styles.css'
import {

    dajSveClanove,
    sudCasti,
    skupstina,
    disciplinskiTuzilac,
    izvrsniOdbor,
    komisije,
    nadzorniOdbor,
    uplateN,
    ordinacije,
    mjesta,
    regije,
    specijalizacije,
    titule,
    statusi,
    statusiZaposlenje,
    obrisiDogadjajN,
    bodoviN,
    kongresiSifre,
    potvrdiBodove,
    upisiEdukaciju,
    obrisiEdukaciju,
    dodajKorisnika,
    upisiLozinku,
    izmjeniOrdinaciju,
    obrisiOrdinaciju,
    upisiOrdinaciju,
    upisiMjesto

  } from "../funkcije.js";

import ReactExport from "react-data-export";
var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Izvjestaji extends Component {
    
        state = { 
          
            brojClanova: "",
            baza: [],
            bazaP: [],
            lista: "",
            clan: "",
            profil: "",
            uplate: "",
            uplateN: "",
            uplateO: [],
            oruzjeObjekat: [],         
            uplateSifre: [],
            datumUplate: "",
            datumLicence: "",
            dateEdukacije: "",
            datumRodjenja: "",
            day: "",
            kongresiEkran: "",
            noviClanEkran: "",
            ordinacijeEkran: "",
            zanimanje: [],
            funkcija: [],
            bodoviNoviO: [],
            kongresiSifreO: [],
            sprema: [],
            dokumenti: [],
            im: "",
            pictures: [],
            uriSlike:"",
            uriDokumenta: logoa4,
            pocetak: "",
            kraj: "",
            month: 10,
            year: 2021,
            statusiO: [],
            statusiZaposlenjeO: [],
            tituleO: [],
            specijalizacijeO: [],
            sudCastiO: [],
            skupstinaO: [],
            nadzorniOdborO: [],
            komisijeO: [],
            izvrsniOdborO: [],
            disciplinskiTuzilacO: [],
            ordinacijeO: [],
            ordinacijeOfilter: [],
            mjestaO: [],
            regijeO: [],
            zoomSlika: "",
            zaposleniO: [],
            zadnjaUplata: ""
         }

         styles6 = {
            border: "1px solid red",
            width: "1260px",
            height: "600px",
            background: "#f7f7ed",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

          styles7 = {
            border: "1px solid red",
            width: "1260px",
            height: "550px",
            background: "#f5cbcb",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

          styles8 = {
            border: "1px solid red",
            width: "1260px",
            height: "600px",
            background: "#f7f7ed",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };
          styles9 = {
            border: "1px solid red",
            width: "1260px",
            height: "200px",
            background: "#f5cbcb",
            //left: "50%",
            "vertical-align": "middle",
            "text-align": "center",
            position: "absolute",
          };

         
       

          stylesG3 = {
           
            width: "1260px",
            height: "600px",           
            left: "50%",
            "margin-left": "-630px",
           // "margin-top": "1190px",
            position: "absolute",
        
            //left: "1%"
          };
       

          slikaDok = {
            width: "250px",
            height: "353px",
            "object-fit": "scale-down",
            //"margin-top": "10px",
          };


          styleHH = {
          
            height: "35px",
         
          };
          styleRR = {
            padding: "10px",
            background: "#f5cbcb",
            border: "1px solid red",
         
          };

         componentDidMount() {

            this.danas();
            this.dajUplateNove();    
            this.dajKongresiSifre(); 
            this.statusiF();
            this.statusiZaposlenjeF();
            this.tituleF();
            this.specijalizacijeF();
            this.ordinacijeF();
            this.mjestaF();
            this.regijeF();
            this.sudCastiF();
            this.skupstinaF();
            this.nadzorniOdborF();
            this.komisijeF();
            this.izvrsniOdborF();
            this.disciplinskiTuzilacF();
            this.dajSveClanoveF();
           

            let filterSlova = document.getElementById("pretragaI");
            let filterRegija = document.getElementById("filterRegija");
            let filterMjesto = document.getElementById("filterMjesto");
            let filterLicenca = document.getElementById("filterLicenca");
            let filterStatus = document.getElementById("filterStatus");
            let filterStatusZ = document.getElementById("filterStatusZ");
            let filterORD = document.getElementById("filterORD");
            let filterSPEC = document.getElementById("filterSPEC");
            let filterTIT = document.getElementById("filterTIT");
            let filterSKUP = document.getElementById("filterSKUP");
            let filterIZVR = document.getElementById("filterIZVR");
            let filterNADZO = document.getElementById("filterNADZO");
            let filterKOMI = document.getElementById("filterKOMI");
            let filterDISCI = document.getElementById("filterDISCI");
            let filterSUD = document.getElementById("filterSUD");
            let filterUplate = document.getElementById("filterUplate");

            filterSlova.addEventListener("keyup", (ff) => {
              if (filterSlova.value.length > 2 || filterSlova.value == "") {
                let slova=filterSlova.value;
                let regijaF=filterRegija.value;
                let mjestoF=filterMjesto.value;
                let licencaF=filterLicenca.value;
                let statusF=filterStatus.value;
                let ordinacijaF=filterORD.value;
                let specijalizacijaF=filterSPEC.value;
                let titulaF=filterTIT.value;
                let skupF=filterSKUP.value;
                let izvrsniF=filterIZVR.value;
                let nadzorniF=filterNADZO.value;
                let komisijeF=filterKOMI.value;
                let disciplinskiF=filterDISCI.value;
                let sudF=filterSUD.value;
                let statusFZ=filterStatusZ.value;
                let uplateF=filterUplate.value;
                this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);             
              }
            });

            filterRegija.addEventListener("change", (ff) => {
                let slova=filterSlova.value;
                let regijaF=filterRegija.value;
                let mjestoF=filterMjesto.value;
                let licencaF=filterLicenca.value;
                let statusF=filterStatus.value;
                let ordinacijaF=filterORD.value;
                let specijalizacijaF=filterSPEC.value;
                let titulaF=filterTIT.value;
                let skupF=filterSKUP.value;
                let izvrsniF=filterIZVR.value;
                let nadzorniF=filterNADZO.value;
                let komisijeF=filterKOMI.value;
                let disciplinskiF=filterDISCI.value;
                let sudF=filterSUD.value;
                let statusFZ=filterStatusZ.value;
                let uplateF=filterUplate.value;
                this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });

           

             filterMjesto.addEventListener("change", (ff) => {
                let slova=filterSlova.value;
                let regijaF=filterRegija.value;
                let mjestoF=filterMjesto.value;
                let licencaF=filterLicenca.value;
                let statusF=filterStatus.value;
                let ordinacijaF=filterORD.value;
                let specijalizacijaF=filterSPEC.value;
                let titulaF=filterTIT.value;
                let skupF=filterSKUP.value;
                let izvrsniF=filterIZVR.value;
                let nadzorniF=filterNADZO.value;
                let komisijeF=filterKOMI.value;
                let disciplinskiF=filterDISCI.value;
                let sudF=filterSUD.value;
                let statusFZ=filterStatusZ.value;
                let uplateF=filterUplate.value;
                this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });

             filterLicenca.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
                let regijaF=filterRegija.value;
                let mjestoF=filterMjesto.value;
                let licencaF=filterLicenca.value;
                let statusF=filterStatus.value;
                let ordinacijaF=filterORD.value;
                let specijalizacijaF=filterSPEC.value;
                let titulaF=filterTIT.value;
                let skupF=filterSKUP.value;
                let izvrsniF=filterIZVR.value;
                let nadzorniF=filterNADZO.value;
                let komisijeF=filterKOMI.value;
                let disciplinskiF=filterDISCI.value;
                let sudF=filterSUD.value;
                let statusFZ=filterStatusZ.value;
                let uplateF=filterUplate.value;
                this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });


             filterStatus.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
              });
             filterORD.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });

             filterSPEC.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);                      
             });

             filterTIT.addEventListener("change", (ff) => {
                let slova=filterSlova.value;
                let regijaF=filterRegija.value;
                let mjestoF=filterMjesto.value;
                let licencaF=filterLicenca.value;
                let statusF=filterStatus.value;
                let ordinacijaF=filterORD.value;
                let specijalizacijaF=filterSPEC.value;
                let titulaF=filterTIT.value;
                let skupF=filterSKUP.value;
                let izvrsniF=filterIZVR.value;
                let nadzorniF=filterNADZO.value;
                let komisijeF=filterKOMI.value;
                let disciplinskiF=filterDISCI.value;
                let sudF=filterSUD.value;
                let statusFZ=filterStatusZ.value;
                let uplateF=filterUplate.value;
                this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);            
             });

             filterSKUP.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);             
             });

             filterIZVR.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });

             filterNADZO.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
             });

             filterKOMI.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);               
             });

             filterDISCI.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
            });

            filterSUD.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
            });

            filterStatusZ.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
            });

            filterUplate.addEventListener("change", (ff) => {
              let slova=filterSlova.value;
              let regijaF=filterRegija.value;
              let mjestoF=filterMjesto.value;
              let licencaF=filterLicenca.value;
              let statusF=filterStatus.value;
              let ordinacijaF=filterORD.value;
              let specijalizacijaF=filterSPEC.value;
              let titulaF=filterTIT.value;
              let skupF=filterSKUP.value;
              let izvrsniF=filterIZVR.value;
              let nadzorniF=filterNADZO.value;
              let komisijeF=filterKOMI.value;
              let disciplinskiF=filterDISCI.value;
              let sudF=filterSUD.value;
              let statusFZ=filterStatusZ.value;
              let uplateF=filterUplate.value;
              this.pretraga(slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
                  ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF);           
            });



          } 

         danas = () => {
            let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
            let daa=new Date();
            this.setState({ day: da });
        
            this.setState({ datumUplate: da });
         
          };

          pretraga = (slova,regijaF,mjestoF,licencaF,statusF,statusFZ,ordinacijaF,specijalizacijaF,titulaF
            ,skupF,izvrsniF,nadzorniF,komisijeF,disciplinskiF,sudF,uplateF) => {
        
            let bazaL = [];
            this.setState({ bazaP: [] });

                let danas = new Date();
                let danasPlus = new Date();
                if(licencaF=="sve"){}
                else{
                  danasPlus.setMonth(danasPlus.getMonth()+parseInt(licencaF));
                }
            
                let danasMinus = new Date();
                if(uplateF=="sve"){}
                else{
                  danasMinus.setMonth(danasMinus.getMonth()-parseInt(uplateF));
                }
               

            
            this.state.baza.forEach((ss) => {


                var uplateNoveX=[];
                var uplateNove=[];
                try{
                this.state.uplateN.forEach((dok) => {
                   
                if(ss.uid == dok.uid){
                  uplateNoveX.push(dok);
                  
                 }
              
                });
                uplateNove=uplateNoveX.slice().reverse();
        
              } catch{
        
              }
        
              let ddnn=""
        
               if(uplateNove.length>0){
               
                uplateNove.every(uplaaa => {
                  if(uplaaa.vrstaUplate=="Članarina" || uplaaa.vrstaUplate=="Članarina2"){
                    ddnn=uplaaa.datumDO;
                    this.setState({ zadnjaUplata: ddnn });
                    //alert(ddnn +" "+ss.ime+" "+ss.prezime);
                    return false;
                  }
                  return true;
        
                })
         
                }



             
              try {
                let ime= ss.ime.toString();
                let prezime= ss.prezime.toString();
                let jmbg= ss.jmbg.toString();
                let broj= ss.broj.toString();
                let mejestoID= ss.mejestoID;
                let regijaID = ss.regijaID;
                let ordinacijaID= ss.ordinacijaId;
                let status= ss.status.toString();
                let statusZaposlenje= ss.statusZaposlenje.toString();
                let specijalizacija1= ss.specijalizacijaId.toString();
                let specijalizacija2= ss.specijalizacijaId2.toString();
                let titula= ss.titula.toString();
                let skupstina= ss.skupstina.toString();
                let izvrsni= ss.izvrsni.toString();
                let nadzorni= ss.nadzorni.toString();
                let komisija1= ss.komisija1.toString();
                let komisija2= ss.komisija2.toString();
                let disciplinski= ss.disciplinski.toString();
                let sud= ss.sud.toString();

                let licDate = new Date();
                licDate.setFullYear((ss.datumLicence.trim().split("-")[0]));
                licDate.setDate(ss.datumLicence.trim().split("-")[2]);
                licDate.setMonth(ss.datumLicence.trim().split("-")[1] - 1);
                licDate.setHours(0, 0, 1, 1);

                let zadnjaUpl = new Date();
                zadnjaUpl.setFullYear((ddnn.trim().split("-")[0]));
                zadnjaUpl.setDate(ddnn.trim().split("-")[2]);
                zadnjaUpl.setMonth(ddnn.trim().split("-")[1] - 1);
                zadnjaUpl.setHours(0, 0, 1, 1);

                
                if (
                  (ime.toLowerCase().includes(slova.toLowerCase()) ||
                  prezime.toLowerCase().includes(slova.toLowerCase()) ||
                  broj.toLowerCase()==(slova.toLowerCase().replace("lc","")) 
                  || jmbg.toLowerCase().includes(slova.toLowerCase())
                  )
        
                  && ( regijaF == "sve" ||
                  regijaF == regijaID
                 )
        
                  && ( mjestoF == "sve" ||
                  mjestoF == mejestoID
                 )
    
                  && ( ordinacijaF == "sve" ||
                  ordinacijaF == ordinacijaID
                 )
                
                  && ( licencaF == "sve" ||
                
                  (licDate <= danasPlus)
                 )

                  && ( statusF == "sve" ||
                  statusF == status
                 )
                  && ( statusFZ == "sve" ||
                  statusFZ == statusZaposlenje
                 )

                 && ( uplateF == "sve" ||
                 (zadnjaUpl <= danasMinus)

                )
 
                  && ( specijalizacijaF == "sve" ||
                  specijalizacijaF == specijalizacija1 ||
                  specijalizacijaF == specijalizacija2
                 )

                  && ( titulaF == "sve" ||
                  titulaF == titula
                 )

                  && ( skupF == "sve" ||
                  skupF == skupstina
                 )

                 && ( izvrsniF == "sve" ||
                 izvrsniF == izvrsni
                 )

                 && ( nadzorniF == "sve" ||
                 nadzorniF == nadzorni
                 )

                 && ( komisijeF == "sve" ||
                 komisijeF == komisija1  ||
                 komisijeF == komisija2
                 )

                 && ( disciplinskiF == "sve" ||
                 disciplinskiF == disciplinski
                 )

                 && ( sudF == "sve" ||
                 sudF == sud
                 )
    

                )
                bazaL.push(
                    ss
                  );
              } catch (er) {
                alert(ss.key);
              }
            });
        
            this.setState({ bazaP: bazaL });
    
            this.setState({
              brojClanova: (
                <div >
                  <label >{bazaL.length}</label>
                  </div>
  
                  )});
          
          };

  

          dajSveClanoveF = () => {
    
            let bb = [];
            this.setState({ baza: [] });
           
            dajSveClanove().then((snapshot) => {
              snapshot.forEach((ss) => {
                let uid = ss.val().uid;
                let adresa = ss.val().adr;
             
               let bodoviX=[];
                ss.child("bodovi").forEach((gg) => {
              
                  let idd=gg.val().id;
                  let seminar=gg.val().seminar;
                  let dat=gg.val().dat;
                  let cif=gg.val().cif;
                  let dr_id=gg.val().dr_id;
                  let slika=gg.val().slika;
    
                bodoviX.push({
    
                  id: idd,
                  seminar: seminar,
                  dat: dat,
                  cif: cif,
                  dr_id: dr_id,
                  slika: slika,
    
                })
    
                })


                let uplateX=[];
                ss.child("uplate").forEach((gg) => {
              
                  let idd=gg.val().id;
                  let datumUplate=gg.val().datumUplate;            
                  let iznosUplate=gg.val().iznosUplate;
                  let dr_id=gg.val().dr_id;
                  let period=gg.val().period;
    
                  uplateX.push({
    
                  id: idd,
                  datumUplate: datumUplate,
                  iznosUplate: iznosUplate,
                  dr_id: dr_id,
                  period: period,
    
                })
    
                })
    
                let broj = ss.val().broj;
                let datumLicence = ss.val().dat_lic.substring(0, 10);
                let disciplinski = ss.val().disciplinski;
                let id = ss.val().id;
                let ime = ss.val().ime;
                let izvrsni = ss.val().izvrsni;
                let jmbg = ss.val().jmbg;
                let komisija1 = ss.val().komisija1;
                let komisija2 = ss.val().komisija2;
                let mail = ss.val().mail;
                let mejestoID = ss.val().mes_id;
                let regijaID = ss.val().regijaId;
                let nadzorni = ss.val().nadzorni;
                let ordinacijaId = ss.val().ord_id;
                let pol = ss.val().pol;
                let prezime = ss.val().prez;
                let skupstina = ss.val().skupstina;
                let specijalizacijaId = ss.val().spec_id;
                let specijalizacijaId2 = ss.val().spec_id2;
                let status = ss.val().status;
                let statusZaposlenje = ss.val().statusZaposlenje;
                let sud = ss.val().sud;
                let telefon = ss.val().tel;
                let titula = ss.val().tit;
                let uplate = ss.val().uplate;
                let uplateNove = ss.val().uplateNove;
                let androidStatus = ss.val().androidStatus;
                let androidDozvola = ss.val().androidDozvola;
                let slika = ss.val().slika;
    
             
    
                let disciplinskiS = "";
                this.state.disciplinskiTuzilacO.map((sss) => {
                  if (sss.broj == disciplinski) {
                    disciplinskiS = sss.naziv;
                  }
                });
                
                let izvrsniS = "";
                this.state.izvrsniOdborO.map((sss) => {
                  if (sss.broj == izvrsni) {
                    izvrsniS = sss.naziv;
                  }
                });
                let komisija1S = "";
                this.state.komisijeO.map((sss) => {
                  if (sss.broj == komisija1) {
                    komisija1S = sss.naziv;
                  }
                });
    
                let komisija2S = "";
                this.state.komisijeO.map((sss) => {
                  if (sss.broj == komisija2) {
                    komisija2S = sss.naziv;
                  }
                });
                let mejestoIDS = "";
                let postanskiBroj = "";
                this.state.mjestaO.map((sss) => {
                  if (sss.id == mejestoID) {
                    mejestoIDS = sss.mjesto;
                    postanskiBroj = sss.ptt;
                  }
                });
                let regijaIDS = "";
                this.state.regijeO.map((sss) => {
                  if (sss.broj == regijaID) {
                    regijaIDS = sss.naziv;
                  }
                });
                let nadzorniS = "";
                this.state.nadzorniOdborO.map((sss) => {
                  if (sss.broj == nadzorni) {
                    nadzorniS = sss.naziv;
                  }
                });
                let ordinacijaIdS = "";
                this.state.ordinacijeO.map((sss) => {
                  if (sss.id == ordinacijaId) {
                    ordinacijaIdS = sss.naziv;
                  }
                });
                let skupstinaS = "";
                this.state.skupstinaO.map((sss) => {
                  if (sss.broj == skupstina) {
                    skupstinaS = sss.naziv;
                  }
                });
                let specijalizacijaIdS = "";
                this.state.specijalizacijeO.map((sss) => {
                  if (sss.id == specijalizacijaId) {
                    specijalizacijaIdS = sss.spec;
                  }
                });
                let specijalizacijaIdS2 = "";
                this.state.specijalizacijeO.map((sss) => {
                  if (sss.id == specijalizacijaId2) {
                    specijalizacijaIdS2 = sss.spec;
                  }
                });
                let statusS = "";
                this.state.statusiO.map((sss) => {
                  if (sss.broj == status) {
                    statusS = sss.naziv;
                  }
                });
                let statusZaposlenjeS = "";
                this.state.statusiZaposlenjeO.map((sss) => {
                  if (sss.broj == statusZaposlenje) {
                    statusZaposlenjeS = sss.naziv;
                  }
                });
                
                let sudS = "";
                this.state.sudCastiO.map((sss) => {
                  if (sss.broj == sud) {
                    sudS = sss.naziv;
                  }
                });
                let titulaS = "";
                this.state.tituleO.map((sss) => {
                  if (sss.broj == titula) {
                    titulaS = sss.naziv;
                  }
                });
    
               //alert(uplate[0]);
    
                bb.push({
                  adresa: adresa,
                  bodovi: bodoviX,
                  broj: broj,
                  datumLicence: datumLicence,
                  disciplinski: disciplinski,
                  id: id,
                  ime: ime,
                  izvrsni: izvrsni,
                  jmbg: jmbg,
                  komisija1: komisija1,
                  komisija2: komisija2,
                  mail: mail,
                  mejestoID: mejestoID,
                  regijaID: regijaID,
                  nadzorni: nadzorni,
                  ordinacijaId: ordinacijaId,
                  pol: pol,
                  prezime: prezime,
                  skupstina: skupstina,
                  specijalizacijaId: specijalizacijaId, 
                  specijalizacijaId2: specijalizacijaId2,            
                  status: status,
                  statusZaposlenje: statusZaposlenje,
                  sud: sud,
                  telefon: telefon,
                  titula: titula,
                  uid: uid,
                  uplate: uplateX,
                  uplateNove: uplateNove,
                  androidStatus: androidStatus,
                  androidDozvola: androidDozvola,
                  slika: slika,
                  disciplinskiS: disciplinskiS,
                  izvrsniS: izvrsniS,
                  komisija1S: komisija1S,
                  komisija2S: komisija2S,
                  mejestoIDS: mejestoIDS,
                  postanskiBroj: postanskiBroj,
                  regijaIDS: regijaIDS,
                  nadzorniS, nadzorniS,
                  ordinacijaIdS: ordinacijaIdS,
                  skupstinaS: skupstinaS,
                  specijalizacijaIdS: specijalizacijaIdS,
                  specijalizacijaIdS2: specijalizacijaIdS2,
                  statusS: statusS,
                  statusZaposlenjeS: statusZaposlenjeS,
                  sudS: sudS,
                  titulaS: titulaS,
                });
              });

              bb= bb.sort((a,b) => parseInt(a.broj) > parseInt(b.broj) ? 1 : parseInt(b.broj) > parseInt(a.broj) ? -1 : 0);

              this.setState({ bazaP: bb });
              this.setState({ baza: bb },()=>{this.dajBodoveNove()});
              this.setState({
                brojClanova: (
                  <div >
                    <label >{bb.length}</label>
                    </div>
    
                    )});

    
            }).then((snapshot) => {

              document.getElementById("filterStatus").value=1;
              document.getElementById("filterStatus").dispatchEvent(new Event("change"));
          
            })
    
           
          };

  
          dajUplateNove = () => {

            uplateN().then((snapshot) => {
             let ff = [];
              snapshot.forEach((clan) => {
                var uid = clan.key;
                clan.forEach((uplata) => {
                
                let key =uplata.key;
                let vrstaUplate = uplata.val().vrstaUplate;
                let iznosUplate = uplata.val().iznosUplate;
                let datumUplate = uplata.val().datumUplate;
                let datumOD = uplata.val().datumOD;
                let datumDO = uplata.val().datumDO;
        
                ff.push({
                  uid: uid,
                  key: key,
                  vrstaUplate: vrstaUplate,
                  iznosUplate: iznosUplate,
                  datumUplate: datumUplate,
                  datumOD: datumOD,
                  datumDO: datumDO,
                
                });       
  
              });
                 
            })
    
          this.setState({ uplateN: ff });
    
          });
        }
    
          
        dajBodoveNove = () => {
    
          bodoviN().then((snapshot) => {
           let ff = [];
            snapshot.forEach((clan) => {
              var uid = clan.key;
              clan.forEach((bod) => {
                let ime="";
                let prezime="";
                let licenca="";
                this.state.baza.forEach((ccc) => {
                if(ccc.uid==uid){
                    ime=ccc.ime;
                    prezime=ccc.prezime;
                    licenca=ccc.broj;
                }

                })
              
                let key =bod.key;
                 let id =bod.val().id;
                 let datum = bod.val().datum;
                 let naziv = bod.val().naziv;
                 let bodovi = bod.val().bodovi;
                 let grad = bod.val().grad;
                 let drzava = bod.val().drzava;
                 let sd = bod.val().sd;
                 let slika = bod.val().slika;
                 let potvrda= bod.val().potvrda;
               
                 ff.push({
                  ime: ime, 
                  prezime: prezime,
                  licenca: licenca, 
                   uid: uid,
                   key: key,
                   id: id,
                   datum: datum,
                   naziv: naziv,
                   bodovi: bodovi,
                   grad: grad,
                   drzava: drzava,
                   sd: sd,
                   slika: slika,
                   potvrda: potvrda,
              
              });  
            
            });
      
           
          })

           this.setState({ bodoviNoviO: ff });
           
        }).then((snapshot) => {

            
        });
      }
    
        dajKongresiSifre = () => {
    
          kongresiSifre().then((snapshot) => {
           let ff = [];
            snapshot.forEach((kongres) => {
              
              let key =kongres.key;
              let naziv = kongres.val().naziv;
              let datum = kongres.val().datum;
              let bodovi = kongres.val().bodovi;
              let sd = kongres.val().sd;
              let grad = kongres.val().grad;
              let drzava = kongres.val().drzava;
      
              ff.push({
              
                key: key,
                naziv: naziv,
                datum: datum,
                bodovi: bodovi,
                sd: sd,
                grad: grad,
                drzava: drzava,
              
            });
      
          })
            this.setState({ kongresiSifreO: ff });
           
    
        });
      }
    
    
    
          tituleF = () => {
            titule().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ tituleO: ff });
            });
          };
    
          statusiF = () => {
            statusi().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
    
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ statusiO: ff });
            });
          };
    
          statusiZaposlenjeF = () => {
            statusiZaposlenje().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ statusiZaposlenjeO: ff });
            });
          };
    
          mjestaF = () => {
            mjesta().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                   
                let broj = ss.val().broj;
                let id = ss.val().id;
                let mjesto = ss.val().mjesto;
                let ptt = ss.val().ptt;
                let regija = ss.val().regija;
        
                ff.push({
                  broj: broj, 
                  id: id,
                  mjesto: mjesto,
                  ptt: ptt,
                  regija: regija,            
                });
              });
        
              this.setState({ mjestaO: ff });
            });
          };
    
          regijeF = () => {
            regije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                   
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ regijeO: ff });
            });
          };
    
          ordinacijeF = () => {
            ordinacije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let key =ss.key; 
                let adresa = ss.val().adresa;
                let id = ss.val().id;
                let mjestoId = ss.val().mjestoId;
                let naziv = ss.val().naziv;
                let nosioc = ss.val().nosioc;
                let sektor = ss.val().sektor;
                let telefon = ss.val().telefon;
                let vrsta = ss.val().vrsta;
        
                ff.push({
                  adresa: adresa, 
                  id: id,
                  mjestoId: mjestoId,
                  naziv: naziv,
                  nosioc: nosioc,
                  sektor: sektor,
                  telefon: telefon,
                  vrsta: vrsta, 
                  key: key,                 
                });
              });
        
              this.setState({ ordinacijeO: ff });
              this.setState({ ordinacijeOfilter: ff });
            });
          };
    

    
          specijalizacijeF = () => {
            specijalizacije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                
        
                let id = ss.val().id;
                let spec = ss.val().spec;
        
                ff.push({
                  id: id,
                  spec: spec,
                });
              });
        
              this.setState({ specijalizacijeO: ff });
            });
          };

          disciplinskiTuzilacF = () => {
            disciplinskiTuzilac().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ disciplinskiTuzilacO: ff });
            });
          };
    
          izvrsniOdborF = () => {
            izvrsniOdbor().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ izvrsniOdborO: ff });
            });
          };
    
          komisijeF = () => {
            komisije().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ komisijeO: ff });
            });
          };
    
          nadzorniOdborF = () => {
            nadzorniOdbor().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ nadzorniOdborO: ff });
            });
          };
    
          skupstinaF = () => {
            skupstina().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
              });
        
              this.setState({ skupstinaO: ff });
            });
          };
    
          sudCastiF = () => {
            sudCasti().then((snapshot) => {
              let ff = [];
              snapshot.forEach((ss) => {
                let broj = ss.val().broj;
                let naziv = ss.val().naziv;
        
                ff.push({
                  broj: broj,
                  naziv: naziv,
                });
    
                
              });
        
              this.setState({ sudCastiO: ff });
            });
          };
    
    

    
     render() { 

      let i = 1;
        return (  <div>


        <div style={this.stylesG3}>
        <div className=" mt-2 ps-2 pe-2 pb-2 pt-1" style={this.styles8}>     
            
            <Form  className="text-center mt-1" onSubmit={this.promjeniOrdinaciju}>
            

            <Row>

            <Col sm="2">
           <h3>Izvještaji</h3>
              </Col>
              <Col sm="2">
              <h3>
                    {this.state.brojClanova}
              </h3>
            </Col>
            <Col sm="2">
            <Form.Control
              type="text"
              id="pretragaI"
              placeholder="pretraga članova"
              
              className=" mb-1"
              />
              </Col>

             <Col sm="2">
              <Input id="filterStatus" as="select" style={this.styleHH}
               className="mt-1">
               <option value="sve">SVI STATUSI</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="1">AKTIVAN ČLAN</option>
              <option value="2">NEAKTIVAN ČLAN</option>
              <option value="3">ISKLJUČEN ČLAN</option>
              <option value="4">UMRLI ČLAN</option>
              

            </Input>
              </Col>

              <Col sm="2">
              <Input id="filterStatusZ" style={this.styleHH} className="mt-1" as="select">
                 <option value="sve">SVI STATUSI ZAPOSLENJA</option>
                  {this.state.statusiZaposlenjeO.map((ss) => {
                   
                      return <option value={ss.broj}>{ss.naziv}</option>;
                    
                  })}
                </Input>
              </Col>

              <Col sm="2">
              <Input id="filterLicenca" as="select" style={this.styleHH}
               className=" mt-1">
               <option value="sve">SVE LICENCE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="6">6 MJESECI PRED ISTEK</option>
              <option value="3">3 MJESECA PRED ISTEK</option> 
              <option value="1">1 MJESEC PRED ISTEK</option> 
              <option value="0">ISTEKLA LICENCA</option>            
            </Input>
              </Col>

             </Row>

              <Row>
              <Col sm="2">
              <Input id="filterUplate" as="select" style={this.styleHH}
               className=" mt-1">
               <option value="sve">SVE UPLATE</option>
              <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              <option value="12">12 MJESECI BEZ UPLATE</option>
              <option value="6">6 MJESECI BEZ UPLATE</option>
             
            </Input>
              </Col>


              <Col sm="2">
              <Input id="filterRegija" as="select" style={this.styleHH}
               className=" mt-1">
               <option value="sve">SVE REGIJE</option>
               <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              {this.state.regijeO.map((d) => {

               return (
                 <option
             
                   value={ d.broj}              
                 >
                   {d.naziv}
                 </option>
               );        
             }) }  
            </Input>
              </Col>

              <Col sm="2">
              <Input id="filterMjesto" as="select" style={this.styleHH}
               className=" mt-1">
               <option value="sve">SVI GRADOVI</option>
               <option value="" disabled>
                . . . . . . . . . . . . .
              </option>
              {this.state.mjestaO.map((d) => {

               return (
                 <option
             
                   value={ d.id}              
                 >
                   {d.mjesto}
                 </option>
               );        
             }) }  
            </Input>
              </Col>

     

            

              <Col sm="2">
              <Input id="filterORD" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE ORDINACIJE</option>
            {this.state.ordinacijeO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.id
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>
           <Col sm="2">
              <Input id="filterSPEC" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE SPECIJALIZACIJE</option>
            {this.state.specijalizacijeO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.id
                            }
                          >
                            {d.spec}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>
              <Col sm="2">
              <Input id="filterTIT" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE TITULE</option>
            {this.state.tituleO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>
          
          
             
              </Row>
             <Row>
              <Col sm="2">
              <Input id="filterSKUP" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE SKUPSTINA</option>
            {this.state.skupstinaO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>

              <Col sm="2">
              <Input id="filterIZVR" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE IZVRŠNI ODBOR</option>
            {this.state.izvrsniOdborO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>

              <Col sm="2">
              <Input id="filterNADZO" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE NADZORNI ODBOR</option>
            {this.state.nadzorniOdborO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>
              <Col sm="2">
              <Input id="filterKOMI" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE KOMISIJE</option>
            {this.state.komisijeO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>

              <Col sm="2">
              <Input id="filterDISCI" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE DISCIPLINSKI T</option>
            {this.state.disciplinskiTuzilacO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>

              <Col sm="2">
              <Input id="filterSUD" as="select" style={this.styleHH} 
              className=" mt-1" >
            <option value="sve">SVE SUD ČASTI</option>
            {this.state.sudCastiO.map((d) => {
                      
                        return (
                          <option
                           
                            value={
                              d.broj
                            }
                          >
                            {d.naziv}
                          </option>
                        );
                      })}
            </Input>
              
              </Col>


            </Row>
                  
              <Row>
            
                  <Form.Control
                    id="selektovanaOrdinacijaI"
                    as="select"

                    multiple
                    style={{ height: "450px", width: "1240px" , overflow: "scroll", }}
                    className="mt-2 mb-3 ms-3"
                  >                 
                    {this.state.bazaP.map((d) => {
               
               let datumLicString= d.datumLicence.split("-")[2]+"."+d.datumLicence.split("-")[1]+"."+d.datumLicence.split("-")[0]
                      return (
                        <option
                          className="text-danger"
                          //onClick={() => this.dajZaposlene()}
                          value={
                            d.ime+";"+d.prezime+";"+d.broj
                          }
                        >
                          {i++ }. {d.ime+ "  " +d.prezime+ " | | " +d.broj+ " | | " +datumLicString+ " | | " +d.mail+ " | | " +d.telefon}
                        </option>
                      );

                    
                    }) }

                  </Form.Control>

               
               
              </Row>




                  <div>
                   

                  <ExcelFile
            filename={
              "Izvjestaj KDS RS"
            }
            element={
              <Button
              className="btn btn-primary centerButton"
              //type="submit"
              style={{ width: "250px" }}
              
              size="sm"
              variant="success"
               
              >
                Snimi tabelu
              </Button>
            }
          >
            <ExcelSheet
              data={this.state.bazaP}
              name={"Clanovi"}
            >
              <ExcelColumn label="Ime" value="ime" />
              <ExcelColumn label="Prezime" value="prezime" />
              <ExcelColumn label="Broj" value="broj" />
              <ExcelColumn label="JMBG" value="jmbg" />
              <ExcelColumn label="Postanski broj" value="postanskiBroj" />
              <ExcelColumn label="Grad" value="mejestoIDS" />
              <ExcelColumn label="Adresa" value="adresa" />
              <ExcelColumn label="Telefon" value="telefon" />
              <ExcelColumn label="Email" value="mail" />
              <ExcelColumn label="Ordinacija" value="ordinacijaIdS" />
   
            </ExcelSheet>

          </ExcelFile>
              
                </div>

            </Form>

    

                
          </div>

        </div>

       
        
        <div style={{  position: 'absolute' }}>{this.state.zoomSlika}</div>

        </div>);
    }
}

export default Izvjestaji;

