import React, { Component, useEffect, useRef  } from 'react';
import Form from "react-bootstrap/Form";
import firebase from "../firebase.js";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Input from "react-bootstrap/InputGroup";
import Resizer from "react-image-file-resizer";
import ReactToPrint from "react-to-print";
import stomatologLogo from "../stomlogo.png";
import logoa4 from "../logoa4.png";
import "moment/locale/sr";
import { DatePickerRC, DatePickerInput } from "rc-datepicker";
import "./myStyles.css";
import "rc-datepicker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import 'react-medium-image-zoom/dist/styles.css'
import {
 
    dajSveClanove,
    sudCasti,
    ordinacije,
    skupstina,
    disciplinskiTuzilac,
    izvrsniOdbor,
    komisije,
    mjesta,
    regije,
    nadzorniOdbor,
    specijalizacije,
    titule,
    statusi,
    statusiZaposlenje,
    upisiSliku,
    uplateSifre,
    obrisiDogadjajN,
    obrisiDogadjajS,
    uplateNclan,
    bodoviNclan,
    kongresiSifre,
    upisiBodove,
    potvrdiBodove,
    upisiSlikuDok,
    izmjeniJednogClana,
    dajJednogClana,
  } from "../funkcije.js";

import ReactExport from "react-data-export";
var ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Jedan extends Component {


    
        state = { 
            kongresiPretraga: [],
            brojClanova: "",
            baza: [],
            bazaP: [],
            lista: "",
            clan: "",
            profil: "",
            uplate: "",
            uplateN: "",
            uplateO: [],
            oruzjeObjekat: [],         
            uplateSifre: [],
            datumUplate: "",
            datumLicence: "",
            datumRodjenja: "",
            day: "",
            kongresiEkran: "",
            zanimanje: [],
            funkcija: [],
            bodoviNoviO: [],
            kongresiSifreO: [],
            sprema: [],
            dokumenti: [],
            im: "",
            pictures: [],
            uriSlike:"",
            uriDokumenta: logoa4,
            pocetak: "",
            kraj: "",
            month: 10,
            year: 2021,
            statusiO: [],
            statusiZaposlenjeO: [],
            tituleO: [],
            specijalizacijeO: [],
            sudCastiO: [],
            skupstinaO: [],
            nadzorniOdborO: [],
            komisijeO: [],
            izvrsniOdborO: [],
            disciplinskiTuzilacO: [],
            ordinacijeO: [],
            mjestaO: [],
            regijeO: [],
            zoomSlika: "",
            vrstaDogadjaja: "",
            kljucDogadjaja: "",
            potvrdaDogadjaja: "",
            slikaDogadjaja: "",
            podaciKongres: "",
            
          };

          styleListaClanova = {
            border: "1px solid red",
            height: "435px",
            width: "420px",
            background: "#f7f7ed",
            overflow: "scroll",
            float: "left",
            "vertical-align": "middle",
            "text-align": "center",
          };


          stylePretraga = {
            border: "1px solid red",
            width: "345px",
            height: "35px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          stylePretraga2 = {
            border: "1px solid red",
            width: "345px",
            height: "35px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };
        
          styleSelekcija = {
            border: "1px solid red",
            width: "207px",
            height: "30px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styleSelekcija2 = {
            border: "1px solid red",
            width: "418px",
            height: "30px",
            "margin-top": "40px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles3 = {
            border: "1px solid red",
            width: "380px",
            height: "720px",
            overflow: "scroll",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles6 = {
            border: "1px solid red",
            width: "380px",
            height: "720px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          
          slikaDok = {
            width: "140px",
            height: "198px",
            "object-fit": "scale-down",
          };

          slikaDok2 = {
            width: "1100px",
            height: "1500px",
            "object-fit": "scale-up",
          };

          stylesProfil = {
            border: "1px solid red",
            width: "380px",
            height: "620px",
            overflow: "scroll",
            padding: "15px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles4 = {
            border: "1px solid red",
            width: "380px",
            height: "450px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
          };

          styles44 = {
            height: "400px",
            overflow: "scroll",
          };
        

          styleSlika = {
            width: "200px",
            height: "200px",
            "border-radius": "50%",
            "object-fit": "cover",
            "object-position": "top",
            border: "2px solid red",
          };

          styleCrvena = {
            background: "#dc3545",
          };

          styleBroj = {
            border: "1px solid red",
            width: "70px",
            height: "35px",
            background: "#f7f7ed",
            float: "left",
            "text-align": "center",
            "vertical-align": "middle",
            padding: "5px",
          };

          stylesD = {
            float: "left",
            "margin-left": "20px",
          };

          styles22 = {
            width: "100px",
            "margin-left": "10px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
            float: "left",
          };

          styles28 = {
            width: "90px",
            "margin-left": "10px",
            background: "#f7f7ed",
            "vertical-align": "middle",
            "text-align": "center",
            float: "left",
          };

          stylesDDD = {
            width: "210px",
          
          };

          stylesZoom = {
            position: "absolute",
            top: "50%",
            left: "50%",
            "margin-top": "-50px",
            "margin-left": "-50px",
            width: "100px",
            height: "100px",
          };

          danas = () => {
            let da = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
           
            this.setState({ day: da });
        
            this.setState({ datumUplate: da });
           
          };

    componentDidMount() {

        this.danas();
        this.sifreUplata();
        this.dajUplateNove();
        this.dajBodoveNove();
        this.dajKongresiSifre();
        this.statusiF();
        this.statusiZaposlenjeF();
        this.tituleF();
        this.specijalizacijeF();
        this.ordinacijeF();
        this.mjestaF();
        this.regijeF();
        this.sudCastiF();
        this.skupstinaF();
        this.nadzorniOdborF();
        this.komisijeF();
        this.izvrsniOdborF();
        this.disciplinskiTuzilacF();
        this.dajClana();
       
       alert(firebase.auth().currentUser.uid);

      //firebase.auth().updateUser(firebase.auth().currentUser.uid, {
       //email: "kristijan.maric.1497@komorastomatologa.com"
     //});

      }


      dajClana = () => {
    
        let bb = [];
        this.setState({ baza: [] });
        
        dajJednogClana(this.props.user).then((ss) => {
          
            let uid = ss.val().uid;
            let adresa = ss.val().adr;
         
           let bodoviX=[];
            ss.child("bodovi").forEach((gg) => {
          
              let idd=gg.val().id;
              let seminar=gg.val().seminar;
              let dat=gg.val().dat;
              let cif=gg.val().cif;
              let dr_id=gg.val().dr_id;
              let slika=gg.val().slika;

            bodoviX.push({

              id: idd,
              seminar: seminar,
              dat: dat,
              cif: cif,
              dr_id: dr_id,
              slika: slika,

            })

            })

            let uplateX=[];
            ss.child("uplate").forEach((gg) => {
          
              let idd=gg.val().id;
              let datumUplate=gg.val().datumUplate;            
              let iznosUplate=gg.val().iznosUplate;
              let dr_id=gg.val().dr_id;
              let period=gg.val().period;

              uplateX.push({

              id: idd,
              datumUplate: datumUplate,
              iznosUplate: iznosUplate,
              dr_id: dr_id,
              period: period,

            })

            })

            let broj = ss.val().broj;
            let datumLicence = ss.val().dat_lic.substring(0, 10);
            let disciplinski = ss.val().disciplinski;
            let id = ss.val().id;
            let ime = ss.val().ime;
            let izvrsni = ss.val().izvrsni;
            let jmbg = ss.val().jmbg;
            let komisija1 = ss.val().komisija1;
            let komisija2 = ss.val().komisija2;
            let mail = ss.val().mail;
            let mejestoID = ss.val().mes_id;
            let regijaID = ss.val().regijaId;
            let nadzorni = ss.val().nadzorni;
            let ordinacijaId = ss.val().ord_id;
            let pol = ss.val().pol;
            let prezime = ss.val().prez;
            let skupstina = ss.val().skupstina;
            let specijalizacijaId = ss.val().spec_id;
            let specijalizacijaId2 = ss.val().spec_id2;
            let status = ss.val().status;
            let statusZaposlenje = ss.val().statusZaposlenje;
            let sud = ss.val().sud;
            let telefon = ss.val().tel;
            let titula = ss.val().tit;
            let uplate = ss.val().uplate;
            let uplateNove = ss.val().uplateNove;
            let androidStatus = ss.val().androidStatus;
            let androidDozvola = ss.val().androidDozvola;
            let slika = ss.val().slika;

         

            let disciplinskiS = "";
            this.state.disciplinskiTuzilacO.map((sss) => {
              if (sss.broj == disciplinski) {
                disciplinskiS = sss.naziv;
              }
            });
            
            let izvrsniS = "";
            this.state.izvrsniOdborO.map((sss) => {
              if (sss.broj == izvrsni) {
                izvrsniS = sss.naziv;
              }
            });
            let komisija1S = "";
            this.state.komisijeO.map((sss) => {
              if (sss.broj == komisija1) {
                komisija1S = sss.naziv;
              }
            });

            let komisija2S = "";
            this.state.komisijeO.map((sss) => {
              if (sss.broj == komisija2) {
                komisija2S = sss.naziv;
              }
            });
            let mejestoIDS = "";
            this.state.mjestaO.map((sss) => {
              if (sss.id == mejestoID) {
                mejestoIDS = sss.mjesto;
              }
            });
            let regijaIDS = "";
            this.state.regijeO.map((sss) => {
              if (sss.broj == regijaID) {
                regijaIDS = sss.naziv;
              }
            });
            let nadzorniS = "";
            this.state.nadzorniOdborO.map((sss) => {
              if (sss.broj == nadzorni) {
                nadzorniS = sss.naziv;
              }
            });
            let ordinacijaIdS = "";
            this.state.ordinacijeO.map((sss) => {
              if (sss.id == ordinacijaId) {
                ordinacijaIdS = sss.naziv;
              }
            });
            let skupstinaS = "";
            this.state.skupstinaO.map((sss) => {
              if (sss.broj == skupstina) {
                skupstinaS = sss.naziv;
              }
            });
            let specijalizacijaIdS = "";
            this.state.specijalizacijeO.map((sss) => {
              if (sss.id == specijalizacijaId) {
                specijalizacijaIdS = sss.spec;
              }
            });
            let specijalizacijaIdS2 = "";
            this.state.specijalizacijeO.map((sss) => {
              if (sss.id == specijalizacijaId2) {
                specijalizacijaIdS2 = sss.spec;
              }
            });
            let statusS = "";
            this.state.statusiO.map((sss) => {
              if (sss.broj == status) {
                statusS = sss.naziv;
              }
            });
            let statusZaposlenjeS = "";
            this.state.statusiZaposlenjeO.map((sss) => {
              if (sss.broj == statusZaposlenje) {
                statusZaposlenjeS = sss.naziv;
              }
            });
            
            let sudS = "";
            this.state.sudCastiO.map((sss) => {
              if (sss.broj == sud) {
                sudS = sss.naziv;
              }
            });
            let titulaS = "";
            this.state.tituleO.map((sss) => {
              if (sss.broj == titula) {
                titulaS = sss.naziv;
              }
            });

           //alert(uplate[0]);

            bb.push({
              adresa: adresa,
              bodovi: bodoviX,
              broj: broj,
              datumLicence: datumLicence,
              disciplinski: disciplinski,
              id: id,
              ime: ime,
              izvrsni: izvrsni,
              jmbg: jmbg,
              komisija1: komisija1,
              komisija2: komisija2,
              mail: mail,
              mejestoID: mejestoID,
              regijaID: regijaID,
              nadzorni: nadzorni,
              ordinacijaId: ordinacijaId,
              pol: pol,
              prezime: prezime,
              skupstina: skupstina,
              specijalizacijaId: specijalizacijaId, 
              specijalizacijaId2: specijalizacijaId2,            
              status: status,
              statusZaposlenje: statusZaposlenje,
              sud: sud,
              telefon: telefon,
              titula: titula,
              uid: uid,
              uplate: uplateX,
              uplateNove: uplateNove,
              androidStatus: androidStatus,
              androidDozvola: androidDozvola,
              slika: slika,
              disciplinskiS: disciplinskiS,
              izvrsniS: izvrsniS,
              komisija1S: komisija1S,
              komisija2S: komisija2S,
              mejestoIDS: mejestoIDS,
              regijaIDS: regijaIDS,
              nadzorniS, nadzorniS,
              ordinacijaIdS: ordinacijaIdS,
              skupstinaS: skupstinaS,
              specijalizacijaIdS: specijalizacijaIdS,
              specijalizacijaIdS2: specijalizacijaIdS2,
              statusS: statusS,
              statusZaposlenjeS: statusZaposlenjeS,
              sudS: sudS,
              titulaS: titulaS,
            });
          
          this.setState({ baza: bb });
        
         
       
        }).then((snapshot) => {
          this.akcija(this.state.baza[0]);


        })

       
      };

 


  
      dajUplateNove = () => {
        let uid=this.props.user;
        uplateNclan(uid).then((snapshot) => {
         let ff = [];
       
         snapshot.forEach((uplata) => {
            
            let key =uplata.key;
            let vrstaUplate = uplata.val().vrstaUplate;
            let iznosUplate = uplata.val().iznosUplate;
            let datumUplate = uplata.val().datumUplate;
            let datumOD = uplata.val().datumOD;
            let datumDO = uplata.val().datumDO;
    
            ff.push({
              uid: uid,
              key: key,
              vrstaUplate: vrstaUplate,
              iznosUplate: iznosUplate,
              datumUplate: datumUplate,
              datumOD: datumOD,
              datumDO: datumDO,
            
            });

          

          });
    
          
       

      this.setState({ uplateN: ff });

      });
    }

      
    dajBodoveNove = () => {
      let uid=this.props.user;
      bodoviNclan(uid).then((snapshot) => {
       let ff = [];
       snapshot.forEach((bod) => {
          
            let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
          
          });

        
        });
  
        this.setState({ bodoviNoviO: ff });
       
     
    });
  }

    dajKongresiSifre = () => {

      kongresiSifre().then((snapshot) => {
       let ff = [];
        snapshot.forEach((kongres) => {
          
          let key =kongres.key;
          let naziv = kongres.val().naziv;
          let datum = kongres.val().datum;
          let bodovi = kongres.val().bodovi;
          let sd = kongres.val().sd;
          let grad = kongres.val().grad;
          let drzava = kongres.val().drzava;
  
          ff.push({
          
            key: key,
            naziv: naziv,
            datum: datum,
            bodovi: bodovi,
            sd: sd,
            grad: grad,
            drzava: drzava,
          
        });
  
      })
        this.setState({ kongresiSifreO: ff });
        this.setState({ kongresiPretraga: ff });

    });
  }



      tituleF = () => {
        titule().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ tituleO: ff });
        });
      };

      statusiF = () => {
        statusi().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {

            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ statusiO: ff });
        });
      };

      statusiZaposlenjeF = () => {
        statusiZaposlenje().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ statusiZaposlenjeO: ff });
        });
      };

      mjestaF = () => {
        mjesta().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let broj = ss.val().broj;
            let id = ss.val().id;
            let mjesto = ss.val().mjesto;
            let ptt = ss.val().ptt;
            let regija = ss.val().regija;
    
            ff.push({
              broj: broj, 
              id: id,
              mjesto: mjesto,
              ptt: ptt,
              regija: regija,            
            });
          });
    
          this.setState({ mjestaO: ff });
        });
      };

      regijeF = () => {
        regije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ regijeO: ff });
        });
      };

      ordinacijeF = () => {
        ordinacije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
               
            let adresa = ss.val().adresa;
            let id = ss.val().id;
            let mjestoId = ss.val().mjestoId;
            let naziv = ss.val().naziv;
            let nosioc = ss.val().nosioc;
            let sektor = ss.val().sektor;
            let telefon = ss.val().telefon;
            let vrsta = ss.val().vrsta;
    
            ff.push({
              adresa: adresa, 
              id: id,
              mjestoId: mjestoId,
              naziv: naziv,
              nosioc: nosioc,
              sektor: sektor,
              telefon: telefon,
              vrsta: vrsta,                
            });
          });
    
          this.setState({ ordinacijeO: ff });
        });
      };

      disciplinskiTuzilacF = () => {
        disciplinskiTuzilac().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ disciplinskiTuzilacO: ff });
        });
      };

      izvrsniOdborF = () => {
        izvrsniOdbor().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ izvrsniOdborO: ff });
        });
      };

      komisijeF = () => {
        komisije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ komisijeO: ff });
        });
      };

      nadzorniOdborF = () => {
        nadzorniOdbor().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ nadzorniOdborO: ff });
        });
      };

      skupstinaF = () => {
        skupstina().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });
          });
    
          this.setState({ skupstinaO: ff });
        });
      };

      sudCastiF = () => {
        sudCasti().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let broj = ss.val().broj;
            let naziv = ss.val().naziv;
    
            ff.push({
              broj: broj,
              naziv: naziv,
            });

            
          });
    
          this.setState({ sudCastiO: ff });
        });
      };

      specijalizacijeF = () => {
        specijalizacije().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            
    
            let id = ss.val().id;
            let spec = ss.val().spec;
    
            ff.push({
              id: id,
              spec: spec,
            });
          });
    
          this.setState({ specijalizacijeO: ff });
        });
      };

      sifreUplata = () => {
        uplateSifre().then((snapshot) => {
          let ff = [];
          snapshot.forEach((ss) => {
            let sifra = ss.key;
    
            let cijena = ss.val().cijena;
            let dozvola = ss.val().dozvola;
            ff.push({
              sifra: sifra,
              cijena: cijena,
              dozvola: dozvola,
            });

          });
            ff.reverse();
    
          this.setState({ uplateO: ff });
        });
      };


      akcija = (clan) => {
        this.setState({ uriSlike: "" });
        try {


          let slika = clan.slika;
          if (clan.slika == null || clan.slika == "" || clan.slika == "1") {
            slika = stomatologLogo;
          }


            let datumLicString= clan.datumLicence.split("-")[2]+"."+clan.datumLicence.split("-")[1]+"."+clan.datumLicence.split("-")[0]
           
          this.setState({ clan: clan });
          this.setState({
            profil: (
              <div id="stampaProfil" className="ms-2 mt-3" style={this.styles3}>
                <label class="labelMali2">Status</label>
                {":  "}
                <label class="labelVeliki2">{clan.statusS}</label>
                <br />  
                <label class="labelMali">Licenca </label>
                {":  "}
                <label class="labelVeliki">{clan.broj}</label>
                <br />
                <label class="labelMali2">Datum licence </label>
                {":  "}
                <label class="labelVeliki2">{datumLicString}</label>
                <br /> 
                <img className="m-2" src={slika} style={this.styleSlika} />
                <br />
               
                <label class="labelMali2">Titula </label>
                {":  "}
                <label class="labelVeliki2">{clan.titulaS}</label>
                <br /> 
               
                <label class="labelMali">Ime </label>
                {":  "}
                <label class="labelVeliki">{clan.ime}</label>
                <br />
                <label class="labelMali">Prezime </label>
                {":  "}
                <label class="labelVeliki">{clan.prezime}</label>
                <br /> 
                 <label class="labelMali2">Specijalizacija 1</label>
                {":  "}
                <label class="labelVeliki2">{clan.specijalizacijaIdS}</label>
                <br />
                <label class="labelMali2">Specijalizacija 2</label>
                {":  "}
                <label class="labelVeliki2">{clan.specijalizacijaIdS2}</label>
                <br />
                <label class="labelMali2">Jmbg </label>
                {":  "}
                <label class="labelVeliki2">{clan.jmbg}</label>
                <hr />

                <label class="labelMali2">Adresa</label>
                {":  "}
                <label class="labelVeliki2">{clan.adresa}</label>
                <br />
                <label class="labelMali2">Mjesto</label>
                {":  "}
                <label class="labelVeliki2">{clan.mejestoIDS}</label>
                <br />
                <label class="labelMali2">Regija</label>
                {":  "}
                <label class="labelVeliki2">{clan.regijaIDS}</label>
                <hr />

                <label class="labelMali2">Email </label>
                {":  "}
                <label class="labelVeliki2">{clan.mail}</label>
                <br />
                <label class="labelMali2">Telefon </label>
                {":  "}
                <label class="labelVeliki2">{clan.telefon}</label>
                <br />
                <label class="labelMali2">Status zaposlenje</label>
                {":  "}
                <label class="labelVeliki2">{clan.statusZaposlenjeS}</label>
                <br />
                <label class="labelMali2">Ordinacija</label>
                {":  "}
                <label class="labelVeliki2">{clan.ordinacijaIdS}</label>
                <br />                    
                <label class="labelMali2">Pol</label>
                {":  "}
                <label class="labelVeliki2">{clan.pol}</label>
                <br />
                <label class="labelMali2">Skupština</label>
                {":  "}
                <label class="labelVeliki2">{clan.skupstinaS}</label>
                <br />
                <label class="labelMali2">Izvršni odbor</label>
                {":  "}
                <label class="labelVeliki2">{clan.izvrsniS}</label>
                <br />
                <label class="labelMali2">Nadzorni odbor</label>
                {":  "}
                <label class="labelVeliki2">{clan.nadzorniS}</label>
                <hr />

                <label class="labelMali2">Komisije 1</label>
                {":  "}
                <label class="labelVeliki2">{clan.komisija1S}</label>
                <br />
                <label class="labelMali2">Komisije 2</label>
                {":  "}
                <label class="labelVeliki2">{clan.komisija2S}</label>
                <br />
                <label class="labelMali2">Disciplinski tužioc</label>
                {":  "}
                <label class="labelVeliki2">{clan.disciplinskiS}</label>
                <br />
                <label class="labelMali2">Sud časti</label>
                {":  "}
                <label class="labelVeliki2">{clan.sudS}</label>
                <br />


                <label class="labelMali2">Android status</label>
                {":  "}
                <label class="labelVeliki2">{clan.androidStatus}</label>
                <br />
                <label class="labelMali2">Android dozvola</label>
                {":  "}
                <label class="labelVeliki2">{clan.androidDozvola}</label>
              
                <br />

                <div  style={{ padding: "10px" }}>
           
              </div>

                <Button
                  className="btn btn-danger centerButton m-3"
                  size="sm"
                  onClick={() => this.izmjena(clan)}
                >
                  Izmijeni podatke
                </Button>
               
                 
              </div>
            ),
          },()=>{
            //const user = firebase.auth().currentUser;
            //user.updateEmail("ljuban.jankovic.4@komorastomatologa.com").then(() => {
         // alert("novi mail je "+firebase.auth().currentUser.email)
            //}).catch((error) => {
             
            //});

          });
    
 
          
    
    
    
        } catch (err) {}

        this.uplateForma(clan);
        this.kongresiForma(clan);
      };

     


      izmjena = (clan) => {
        let slika = clan.slika;
        let slika1 = clan.slika;
      
        if (clan.slika == null || clan.slika == ""|| clan.slika == "1") {
          slika = stomatologLogo;
        }

        try {
          if (clan.datumLicence == "" || clan.datumLicence.includes("undefined")) {
          } else {
          
            this.setState({ datumLicence: clan.datumLicence });
          }
        } catch {
          alert("greska datum licence");
        }
   
       
        this.setState({ clan: clan });
        this.setState({
          profil: (
            <div id="izmjeniProfil" className="ms-2 mt-3" style={this.stylesProfil}>
              <Form onSubmit={this.snimiPromjene}>
 
             
   
              <img className="m-2" id="slikaProfil" src={slika} style={this.styleSlika} />
        
               

            
            <br />
       
              <h6>Adresa:</h6>
                <Form.Control
                  required
                  type="text"
                  id="adresaI"
                  placeholder="Adresa člana"
                  defaultValue={clan.adresa}
                />
              <br />
              <h6>Mjesto:</h6>
                <Input id="mjestoI" as="select">
                  {this.state.mjestaO.map((ss) => {
                    if (ss.id == clan.mejestoID) {
                      return (
                        <option selected value={ss.id}>
                          {ss.mjesto}
                        </option>
                      );
                    } else {
                      return <option value={ss.id}>{ss.mjesto}</option>;
                    }
                  })}
                </Input>

               
                <br />
                <h6>E-mail:</h6>
                <Form.Control
                  required
                  type="text"
                  id="emailI"
                  placeholder="E-mail"
                  defaultValue={clan.mail}
                />
              <br />
               <h6>Telefon:</h6>
                <Form.Control
                  required
                  type="text"
                  id="telefonI"
                  placeholder="Telefon"
                  defaultValue={clan.telefon}
                />
              <br />
             

                <Button
                  className="m-2 btn btn-success centerButton"
                  size="sm"
                  onClick={() => this.akcija(clan)}
                >
                  Nazad
                </Button>
                <Button
                  className="m-2 btn btn-danger centerButton"
                  size="sm"
                  type="submit"
                >
                  Snimi promjene
                </Button>
              </Form>
            </div>
          ),
        });
      };
//*
      snimiPromjene = (ev) => {
        ev.preventDefault();
        let clan = this.state.clan;
        let uid = clan.uid;
       
        let adr = document.getElementById("adresaI").value;
        let mail = document.getElementById("emailI").value;
        let tel = document.getElementById("telefonI").value;
    
        let mes_id = document.getElementById("mjestoI").options[
          document.getElementById("mjestoI").selectedIndex
        ].value;

     
          if (window.confirm("Izmijeni podatke o članu?")) {


            izmjeniJednogClana(
              adr,
              mail,
              tel,
              mes_id,
              uid
            )
  
            .then(() => {
            clan.adresa=adr;
            clan.mail=mail;
            clan.telefon=tel;
            clan.mejestoID=mes_id;

            let mejestoIDS = "";
            this.state.mjestaO.map((sss) => {
              if (sss.id == mes_id) {
                mejestoIDS = sss.mjesto;
              }
            });
            clan.mejestoIDS=mejestoIDS;

            let ff=[];
            ff.push(clan);
            this.setState({clan: clan });
            this.setState({baza: ff });
            this.akcija(clan);
            });
          }
      
      };
     // */

      datumLicence = (date) => {
        let ddd =
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.setState({ datumLicence: ddd });
        
      };

     

      datumUplate = (date) => {

        var mm=(date.getMonth() + 1).toString();
        var dd=date.getDate().toString();
        if(mm.length==1){mm="0"+mm}
        if(dd.length==1){dd="0"+dd}
       
          let ddd =
            date.getFullYear() + "-" + mm + "-" + dd;
          this.setState({ datumUplate: ddd });
       
        };
  

      sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }



      uplateForma = (clan) => {
        this.setState({ kraj: "" });
        this.setState({ pocetak: "" });
        //this.setState({ datumUplate: "" });

        let uplate = clan.uplate;
        if(uplate=="" || uplate==null || uplate==undefined)
        { 
          uplate=[];
        }else{
          uplate=clan.uplate.slice().reverse();
        }
      
        
        var uplateNoveX=[];
        var uplateNove=[];
        try{
        this.state.uplateN.forEach((dok) => {
        if(dok.uid==clan.uid){
          uplateNoveX.push(dok)
         }
      
        });
        uplateNove=uplateNoveX.slice().reverse();

      } catch{

      }


       if(uplateNove.length>0){

        uplateNove.every(uplaaa => {
          if(uplaaa.vrstaUplate=="Članarina" || uplaaa.vrstaUplate=="Članarina2"){
            let ddnn=uplaaa.datumDO;
            this.setState({ pocetak: ddnn });
            return false;
          }
          return true;

        })

        
     
 
        }

        this.setState({
          uplateSifre: (
            <div className=" mt-3 ms-2 px-2 pb-2 pt-1" style={this.styles4}>
              <h5>UPLATE</h5>
              <div  className="text-center mt-1" style={this.styles44}>
                <div
                  id="selektovanaUplataI"
                 
                 // style={{ height: "600px" }}
                  
                >

             { uplateNove.map((d) => {
               if(d.vrstaUplate=="Članarina" || d.vrstaUplate=="Članarina2"){

           
               var datU =d.datumUplate.substring(0, 10);
               var d1=d.datumOD.substring(0, 10);
               var d2=d.datumDO.substring(0, 10);
               datU=datU.split("-")[2]+"."+datU.split("-")[1]+"."+datU.split("-")[0]

                d1=d1.split("-")[1] +"."+ d1.split("-")[0];
                d2=d2.split("-")[1] +"."+ d2.split("-")[0];
               
                      return (
                        <button
                        className="text-dark btn btn-outline-danger m-1 btn-sm uplateButton"
                          //onClick={() => this.akcija(clan)}
                          key={d.key}
                                               
                        >

                          {datU}  {" | "+d1+"-"+d2+" | "}
                          {d.iznosUplate},00KM {" | " +  d.vrstaUplate}
                        </button>
                      );
                

                    }
                    else{


                      var datU =d.datumUplate.substring(0, 10);
                    
                      datU=datU.split("-")[2]+"."+datU.split("-")[1]+"."+datU.split("-")[0]
       
                     
                      
                             return (
                               <button
                                 
                                 className="btn btn-outline-danger m-1 btn-sm uplateButton"
                                //onClick={() => this.akcija(clan)}
                                key={d.key}
                                                      
                               >
       
                                 {datU}  {" | "}
                                 {d.iznosUplate},00KM {" | " +  d.vrstaUplate}
                               </button>
                             );
                       

                    }
                  })}

         
                </div>
              
              </div>
            </div>
          ),
        });
  

      };

     

      kongresiForma = (clan) => {
        this.setState({ uriDokumenta: ""});
        let bodovi = clan.bodovi;
      
        let bodoviNovi = this.state.bodoviNoviO;
        if(bodovi=="" || bodovi==null || bodovi == undefined)
        { 
          bodovi=[];
        }else{
          //bodovi=clan.bodovi.slice().reverse();
        }
        let bodoviNoviX=[];
        if(bodoviNovi=="" || bodoviNovi==null || bodoviNovi==undefined)
        { 
          bodoviNoviX=[];
        }else{
          bodoviNoviX=bodoviNovi.slice().reverse();
        }


        let kongresiSifreL=this.state.kongresiSifreO;
        let kongresiSifreX=[];
        if(kongresiSifreL=="" || kongresiSifreL==null || kongresiSifreL==undefined)
        { 
            kongresiSifreX=[];
        }else{
            kongresiSifreX=kongresiSifreL.slice().reverse();
         
        }



      let bodoviU=0;
      let bodoviP=0;
      let bodoviN=0;

      let licDate = new Date();
      licDate.setFullYear((clan.datumLicence.trim().split("-")[0]-5));
      licDate.setMonth(clan.datumLicence.trim().split("-")[1] - 1);
      licDate.setDate(clan.datumLicence.trim().split("-")[2]);

        licDate.setHours(0, 0, 1, 1);

        let licDate2 = new Date();
        licDate2.setFullYear(clan.datumLicence.trim().split("-")[0]);
        licDate2.setMonth(clan.datumLicence.trim().split("-")[1] - 1);
        licDate2.setDate(clan.datumLicence.trim().split("-")[2]);
  
          licDate2.setHours(0, 0, 1, 1);

        this.setState({
          kongresiEkran: (
            <div className=" mt-3 ms-2 ps-2 pe-2 pb-2 pt-1" style={this.styles6}>     
          
              <div  className="text-center mt-1">
             <h5>EDUKACIJE</h5>
                  
                    <div
                      id="selektovanDogadjajI"
                      as="select"
                      multiple
                      style={{ height: "200px", width: "365px",  overflow: "scroll", }}
                      
                    >                 
                      {bodoviNoviX.map((d) => {
                          let uDatumu=false;
                          let eduDate = new Date();
                              eduDate.setFullYear(d.datum.trim().split("-")[0]);
                              eduDate.setMonth(d.datum.trim().split("-")[1] - 1);
                              eduDate.setDate(d.datum.trim().split("-")[2]);

                                eduDate.setHours(0, 0, 1, 1);

                                if (licDate <= eduDate && eduDate <= licDate2) {
                                  uDatumu=true;
                                }
                         
                        if(d.uid==clan.uid){
                          if(uDatumu){ bodoviU=bodoviU+parseInt(d.bodovi);}
                         
                        if (d.potvrda == "da") {
                          if(uDatumu){bodoviP=bodoviP+parseInt(d.bodovi);}
                          
                        var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]                    
                        return (
                          <button
                            className="text-dark btn btn-outline-danger m-1 btn-sm uplateButton"                      
                            key={d.key}
                            onClick={() => this.slikeUbaci(d.slika,"nove;"+d.key+";"+d.slika+";"+d.potvrda)}
                            
                            value={
                              "nove;"+d.key+";"+d.slika+";"+d.potvrda
                            }
                          >
                            {dat+" | " +d.naziv+ " | " +d.bodovi}
                          </button>
                        );
                          }else{
                            if(uDatumu){bodoviN=bodoviN+parseInt(d.bodovi);}
                            
                            var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]                    
                            return (
                              <button
                              className="text-danger btn btn-outline-danger m-1 btn-sm uplateButton"                      
                              key={d.key}
                              onClick={() => this.slikeUbaci(d.slika,"nove;"+d.key+";"+d.slika+";"+d.potvrda)}
                              
                              value={
                                  "nove;"+d.key+";"+d.slika+";"+d.potvrda
                                }
                              >
                                {dat+" | " +d.naziv+ " | " +d.bodovi}
                              </button>
                            );



                          }


                      }
                      }) }

                      {bodovi.map((d) => {
                      
                        var dat=d.dat.substring(0, 10);
                        var dat=dat.split("-")[2]+"."+dat.split("-")[1]+"."+dat.split("-")[0]
                        
                        return (
                          <button                          
                            className="text-secondary btn btn-outline-danger m-1 btn-sm uplateButton"                      
                            key={d.key}
                            onClick={() => this.slikeUbaci(d.slika,"stare;"+d.id+";"+d.slika+"; da")}
                            value={
                              "stare;"+d.id+";"+d.slika+"; da"
                            }
                          >
                            {dat+" | " +d.seminar+ " | " +d.cif}
                          </button>
                        );
                      })}

    
                    </div>
         
                    <img
                    style={{ width: "100px"}}
                      id="sliketina" 
                      style={this.slikaDok}
                      className=" ms-2 mt-1"
                      onClick={this.handleShowDialog}
                    />
                  
                    <div className="mt-1" style={{ width: "370px"}}>
                    <label>BODOVI</label><label className="ms-2">  ukupno:{bodoviU}</label>     <label className="ms-2">potrvđeni:{bodoviP}</label>   <label className=" ms-2">nepotrvđeni:{bodoviN}</label>
                    </div>

                    <div id="kongresSifraI" as="select" style={{ width: "365px",height: "180px" , overflow: "scroll"}} className="mr-1 ps-1 mt-3 "  multiple defaultValue={"-"}>
                 
                  {kongresiSifreX.map((d) => {

                  var dat=d.datum.split("-")[2]+"."+d.datum.split("-")[1]+"."+d.datum.split("-")[0]
                      return (
                        
                        <button
                        className="text-dark btn btn-outline-danger m-1 btn-sm uplateButton"
                        onClick={() => this.selektovanKongres(d.datum+";" + d.naziv + ";" + d.bodovi+ ";" + d.grad+ ";" + d.drzava+ ";" + d.sd+ ";" + d.key)}
                         
                        >
                          {dat+" | " +d.naziv+ " | " +d.bodovi}
                        </button>
                      );
                  })}
                </div>

                    
                  <div className='mt-2'>
                    <Button
                      id="dodajDokument"
                      className="btn btn-danger me-1"
                      onClick={() => this.dodajBodove()}
                      size="sm"
                   
                    >
                      Dodaj edukaciju
                    </Button>
                    <Button
                      id="brisiBodove"
                      className=" me-1"
                      onClick={() => this.brisiBodove()}
                      size="sm"
                      variant="outline-dark"
                    >
                      Brisi edukaciju
                    </Button>
                 
                  
                  <label for="file-upload"  class="meme" style={{
                    border: "1px solid red",
                    width: "120px",
                    color: "#dc3545",
                    "font-weight": "bold",
                   
                  }}> 
                      Dodaj sliku
                    </label>
                    <input
                    
                      id="file-upload"
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={this.fileChangedHandlerD}
                    />
                  
    
                    </div>

   
              </div>
            </div>
          ),
        });
        
        try{
          this.slikeUbaci(bodovi[0].slika,"")
        }catch(ero){
          this.slikeUbaci("","");
          
        }
        try{
          bodoviNovi.map((d) => {                       
            if(d.uid==clan.uid){
              this.slikeUbaci(d.slika,"")
            }
          });
         
        }catch(ero){
          this.slikeUbaci("","");
        }


        
      };

     
      selektovanKongres = (podaci) => {

        this.setState({ podaciKongres: podaci });


      }

      dodajBodove = () => {

        let uid=this.state.clan.uid; 
        
        let selektovanDogadjaj = this.state.podaciKongres.split(";");
        if(selektovanDogadjaj==""){alert("Selektujte jednu edukaciju")}
        else{
        let datum = selektovanDogadjaj[0];
        let naziv = selektovanDogadjaj[1];
        let bodovi = selektovanDogadjaj[2];
        let grad = selektovanDogadjaj[3];
        let drzava = selektovanDogadjaj[4];
        let sd = selektovanDogadjaj[5];
        let id = selektovanDogadjaj[6];


        if (window.confirm("Dodajte novu edukaciju?")) {
        if(naziv=="-" || naziv==undefined || naziv==null){
          alert("Niste selektovali ni jedanu edukaciju!")
        }else{
        upisiBodove(     
        uid,
        id,
        datum,
        naziv,
        bodovi,
        grad,
        drzava,
        sd,
        "ne",
        ).then(() => {
        //alert("Događaj je dodan");

        bodoviNclan(uid).then((snapshot) => {
          let ff = [];
             
          snapshot.forEach((bod) => {
             
              let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });
   
           this.setState({ bodoviNoviO: ff });
           });
         
          
 
 
          }).then((snapshot) => {
            this.setState({ podaciKongres: "" });
           
          this.sleep(500).then(r => {alert("Edukacija je dodana!")});
          this.akcija(this.state.clan);
          ;
 
           });
     

        });
      }
    }
  }
      }

      brisiBodove = () =>{
        let uid=this.state.clan.uid;
        if (window.confirm("Obriši selektovanu edukaciju?")) {
          let dugme = document.getElementById("brisiBodove");
          dugme.disabled = true;
          try{
         
          let vrstaDogadjaja = this.state.vrstaDogadjaja;
          let kljucDogadjaja = this.state.kljucDogadjaja;
          let slikaDogadjaja = this.state.slikaDogadjaja;
          let potvrdaDogadjaja = this.state.potvrdaDogadjaja;
       
          if(vrstaDogadjaja=="nove" && potvrdaDogadjaja=="ne"){

          obrisiDogadjajN(this.state.clan.uid, kljucDogadjaja).then(() => {
       
          dugme.disabled = false;


        bodoviNclan(uid).then((snapshot) => {
          let ff = [];
           
          snapshot.forEach((bod) => {
             
             let key =bod.key;
             let id =bod.val().id;
             let datum = bod.val().datum;
             let naziv = bod.val().naziv;
             let bodovi = bod.val().bodovi;
             let grad = bod.val().grad;
             let drzava = bod.val().drzava;
             let sd = bod.val().sd;
             let slika = bod.val().slika;
             let potvrda= bod.val().potvrda;
           
             ff.push({
               uid: uid,
               key: key,
               id: id,
               datum: datum,
               naziv: naziv,
               bodovi: bodovi,
               grad: grad,
               drzava: drzava,
               sd: sd,
               slika: slika,
               potvrda: potvrda,
             
             });

           });
          
        
   
           this.setState({ bodoviNoviO: ff },()=>{this.akcija(this.state.clan)});
           this.sleep(500).then(r => {alert("Edukacija je obrisana!")});
           })

          });
          
        if (slikaDogadjaja == null || slikaDogadjaja == "" || slikaDogadjaja == undefined || slikaDogadjaja == "1" ||slikaDogadjaja == "undefined") {

        }
        else{
          
          var ref1 = firebase.storage().refFromURL(slikaDogadjaja);    
          ref1
            .delete()
            .then(function () {
              
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }

          } else {
            alert("Brisanje edukacije nije dozvoljeno! Možete brisati samo edukaciju koju ste sami dodali")
            dugme.disabled = false;
          }



       
        } catch (err) {
          dugme.disabled = false;
          alert("Niste selektovali ni jedanu edukaciju!");
        }
 
        }
      }


      dodajSlikuDoc = (uri) => {
        
        let clan = this.state.clan;  
        let uid = clan.uid.toString();
        
        try{
       
          let vrstaDogadjaja = this.state.vrstaDogadjaja;
          let kljucDogadjaja = this.state.kljucDogadjaja;
          let slika = this.state.slikaDogadjaja;
          let potvrdaDogadjaja = this.state.potvrdaDogadjaja;

        if(vrstaDogadjaja=="nove" && potvrdaDogadjaja=="ne"){

        if (slika == null || slika == "" || slika == undefined || slika == "1" ||slika == "undefined") {
              
        }
        else{
          //alert("brisem "+slika)
          var ref1 = firebase.storage().refFromURL(slika);    
          ref1
            .delete()
            .then(function () {
              //alert("Dokument " + naziv + " obrisan!");
            })
            .catch(function (error) {
              alert("Greska pri brisanju slike");
            });
        }



        var storageRef = firebase.storage().ref();
        var ref = storageRef.child("SlikeDogadjaja");
 
          var d = new Date();
          var n = d.getTime();
    
          var array, binary, i;
          binary = atob(uri.split(",")[1]);
          array = [];
          i = 0;
          while (i < binary.length) {
            array.push(binary.charCodeAt(i));
            i++;
          }
          let file = new Blob([new Uint8Array(array)], {
            type: "image/jpeg",
          });
          
          const uploadTask = ref.child(uid).child(n.toString()).put(file);
          
          uploadTask.on(
            "state_changed",
            function (snapshot) {},
            function (error) {
         
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        
  
                  var gdje = "/bodoviNovi/"+ uid +"/"+ kljucDogadjaja;   
                  upisiSlikuDok(gdje, downloadURL.toString()).then(() => {
                    bodoviNclan(uid).then((snapshot) => {
                      let ff = [];
                      
                      snapshot.forEach((bod) => {
                         
                         let key =bod.key;
                         let id =bod.val().id;
                         let datum = bod.val().datum;
                         let naziv = bod.val().naziv;
                         let bodovi = bod.val().bodovi;
                         let grad = bod.val().grad;
                         let drzava = bod.val().drzava;
                         let sd = bod.val().sd;
                         let slika = bod.val().slika;
                         let potvrda= bod.val().potvrda;
                       
                         ff.push({
                           uid: uid,
                           key: key,
                           id: id,
                           datum: datum,
                           naziv: naziv,
                           bodovi: bodovi,
                           grad: grad,
                           drzava: drzava,
                           sd: sd,
                           slika: slika,
                           potvrda: potvrda,
                         
                         });
            
                       });
                      
                
               
                       this.setState({ bodoviNoviO: ff },()=>{this.kongresiForma(this.state.clan)});
                       alert("Slika edukacije je dodana");
                       })


                  });
                  

               
            }
            
          );
            

          });

           }               
                  else{    
                    let v = document.getElementById("sliketina");
                    let sss=logoa4;
                   if(slika==undefined || slika==null || slika==""|| slika=="undefined" ){
                    this.setState({ uriDokumenta: sss});
                    v.src = sss;
                   }else{
                   
                      v.src = slika;
                      this.setState({ uriDokumenta: slika});
                      
                  }

                alert("Nije dozvoljeno dodavati sliku potvrđenoj edukaciji!")
                }
        
        }
        catch(ero){
          alert("Niste selektovali ni jedanu edukaciju")
        }
          

      };


      slikeUbaci = (slika,podaci) => {
        if(podaci==""){}
        else{
          let selektovanDogadjaj = podaci.split(";");
          let vrstaDogadjaja = selektovanDogadjaj[0];
          let kljucDogadjaja = selektovanDogadjaj[1];
          let slikaDogadjaja = selektovanDogadjaj[2];
          let potvrdaDogadjaja = selektovanDogadjaj[3];
          //alert(selektovanDogadjaj);

          this.setState({ vrstaDogadjaja: vrstaDogadjaja});
          this.setState({ kljucDogadjaja: kljucDogadjaja});
          this.setState({ potvrdaDogadjaja: potvrdaDogadjaja});
          this.setState({ slikaDogadjaja: slikaDogadjaja});
          


        }
       
        let v = document.getElementById("sliketina");
        let sss=logoa4
       if(slika==undefined || slika==null || slika==""|| slika=="undefined" ){
        this.setState({ uriDokumenta: sss});
        v.src = sss;
       }else{
       
          v.src = slika;
          this.setState({ uriDokumenta: slika});
          
      }
     
       
      
      };

      fileChangedHandlerD = (event) => {
        var fileInput = false;
        if (event.target.files[0]) {
          fileInput = true;
        }
        if (fileInput) {
          Resizer.imageFileResizer(
            event.target.files[0],
            800,
            1200,
            "JPEG",
            90,
            0,
            (uri) => {
            
             
              let v = document.getElementById("sliketina");
              let staro=v.src;
              v.src = uri;
              
               this.sleep(500).then(r => {
                       if (window.confirm("Dodaj sliku edukacije?")) {
                        this.dodajSlikuDoc(uri);
                        //this.setState({ uriDokumenta: uri});
                       }else{
                        v.src = staro;
                          }
                    })

            },
            "base64"
          );
        }
      };

      handleShowDialog = () => {
        this.setState({ zoomSlika: (<div>

            <dialog           
            //style={this.stylesZoom}
            className="popup2"
            open
            onClick={this.ukiniSliku}
          >
            <img
              className="image"
              src={this.state.uriDokumenta}
              onClick={this.ukiniSliku}
              alt="no image"
            />
          </dialog>


        </div>) });
     
      };

      ukiniSliku = () => {
        this.setState({ zoomSlika:""});

      }


//
    render() { 
        let i = 1;
     
        return (
        <div>
       
    
        <div style={{ float: "left" }}>{this.state.profil}</div>    
        <div style={{ float: "left" }}>{this.state.kongresiEkran}</div>
        <div style={{ float: "left" }}>{this.state.uplateSifre}</div>
        <div style={{  position: 'absolute' }}>{this.state.zoomSlika}</div>

        </div>);
    }
}


 
export default Jedan;