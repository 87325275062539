import firebase from "./firebase.js";
import drugi from "./firebase2";

export async function login(email, pass) {
  var rezultat = await firebase
    .auth()
    .signInWithEmailAndPassword(email, pass)
    .catch(function (error) {

    });
  return rezultat;
}

export async function dodajKorisnika(email, pass) {
  let uu = await drugi
    .auth()
    .createUserWithEmailAndPassword(email, pass)
    .catch(function (error) {
      alert("Greška pri kreiranju korisnika  "+error);
    });

  return uu;
}


export async function upisiClana(
  adr,
  androidDozvola,
  broj,
  dat_lic,
  disciplinski,             
  ime,
  izvrsni,
  jmbg,
  komisija1,
  komisija2,
  mail,
  mes_id,
  regijaId,
  nadzorni,
  ord_id,
  pol,
  prez,
  skupstina,
  spec_id,
  spec_id2,
  status,
  statusZaposlenje,
  sud,
  tel,
  tit,
  uid
) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .set({
      adr: adr,
      androidDozvola: androidDozvola,
      androidStatus: "neaktivan",
      broj: broj,
      dat_lic: dat_lic, 
      disciplinski: disciplinski,           
      ime: ime,
      izvrsni: izvrsni,
      jmbg: jmbg,
      komisija1: komisija1,
      komisija2: komisija2,
      mail: mail,
      mes_id: mes_id,
      regijaId: regijaId,
      nadzorni: nadzorni,
      ord_id: ord_id,
      pol: pol,
      prez: prez,
      skupstina: skupstina,
      slika: "1",
      spec_id: spec_id,
      spec_id2: spec_id2,
      status: status,
      statusZaposlenje: statusZaposlenje,
      sud: sud,
      tel: tel,
      tit: tit,
      id: uid,
      uid: uid
    });
}

export async function upisiLozinku(uid, lozinka) {
  firebase
    .database()
    .ref("/lozinke/"+uid)
    .set({
      lozinka: lozinka,
    })
    .catch(function (error) {
      alert(error);
    });
}



export async function dajSveClanove() {
  var snapshot = await firebase.database().ref("/clan/").once("value");

  return snapshot;
}

export async function dajJednogClana(uid) {
  var snapshot = await firebase.database().ref("/clan/"+uid).once("value");

  return snapshot;
}

export async function napomene(uid) {
  var snapshot = await firebase.database().ref("/napomene/" + uid + "/").once("value");

  return snapshot;
}



export async function sudCasti() {
  var snapshot = await firebase.database().ref("/sudCasti/").once("value");

  return snapshot;
}
export async function ordinacije() {
  var snapshot = await firebase.database().ref("/ordinacije/").once("value");

  return snapshot;
}
export async function skupstina() {
  var snapshot = await firebase.database().ref("/skupstina/").once("value");

  return snapshot;
}

export async function disciplinskiTuzilac() {
  var snapshot = await firebase.database().ref("/disciplinskiTuzilac/").once("value");

  return snapshot;
}

export async function izvrsniOdbor() {
  var snapshot = await firebase.database().ref("/izvrsniOdbor/").once("value");

  return snapshot;
}

export async function komisije() {
  var snapshot = await firebase.database().ref("/komisije/").once("value");

  return snapshot;
}

export async function mjesta() {
  var snapshot = await firebase.database().ref("/mjesta/").once("value");

  return snapshot;
}

export async function regije() {
  var snapshot = await firebase.database().ref("/regije/").once("value");

  return snapshot;
}


export async function nadzorniOdbor() {
  var snapshot = await firebase.database().ref("/nadzorniOdbor/").once("value");

  return snapshot;
}
export async function uplateSifre() {
  var snapshot = await firebase.database().ref("/uplateSifre/").once("value");

  return snapshot;
}
export async function specijalizacije() {
  var snapshot = await firebase.database().ref("/specijalizacije/").once("value");

  return snapshot;
}

export async function statusi() {
  var snapshot = await firebase.database().ref("/statusi/").once("value");

  return snapshot;
}

export async function statusiZaposlenje() {
  var snapshot = await firebase.database().ref("/statusiZaposlenje/").once("value");

  return snapshot;
}

export async function titule() {
  var snapshot = await firebase.database().ref("/titule/").once("value");

  return snapshot;
}

export async function upisiUplatu(
  uid,
  vrstaUplate,
  datumUplate,
  iznosUplate,
  datumOD,
  datumDO
) {

  var d = new Date();
  var n = d.getTime();

  firebase
    .database()
    .ref("/uplateNove/" + uid+ "/"+n)
    .set({
      datumUplate: datumUplate,
      iznosUplate: iznosUplate,
      vrstaUplate: vrstaUplate,
      datumOD: datumOD,
      datumDO: datumDO,
    });
}


export async function upisiBodove(
  uid,
  id,
  datum,
  naziv,
  bodovi,
  grad,
  drzava,
  sd,
  potvrda,
 
) {
  var d = new Date();
  var n = d.getTime();

  firebase
    .database()
    .ref("/bodoviNovi/" + uid+ "/"+n)
    .set({
      datum: datum,
      id: id,
      naziv: naziv,
      bodovi: bodovi,
      grad: grad,
      drzava: drzava,     
      sd: sd,
      potvrda: potvrda,
    });
}

export async function potvrdiBodove(
  uid,
  kljuc,
  id,
  datum,
  naziv,
  bodovi,
  grad,
  drzava,
  sd,
  potvrda,
 
) {
 

  firebase
    .database()
    .ref("/bodoviNovi/" + uid+ "/"+kljuc)
    .update({
      datum: datum,
      id: id,
      naziv: naziv,
      bodovi: bodovi,
      grad: grad,
      drzava: drzava,     
      sd: sd,
      potvrda: potvrda,
    });
}

export async function upisiEdukaciju(
  datum,
  naziv,
  bodovi,
  grad,
  drzava,
  sd,
 
 
) {
  var rand1=Math.floor(Math.random() * 58)+ 1;
  var rand2=Math.floor(Math.random() * 990)+ 1;
  let datumS = new Date();
  datumS.setFullYear(datum.split("-")[0]);
  datumS.setDate(datum.split("-")[2]);
  datumS.setMonth(datum.split("-")[1] - 1);
  datumS.setHours(0, 0, rand1, rand2);

  var key=datumS.getTime();

  firebase
    .database()
    .ref("/kongresiSifre/" + key)
    .set({
      datum: datum,
      id: key,
      naziv: naziv,
      bodovi: bodovi,
      grad: grad,
      drzava: drzava,     
      sd: sd,
     
    });
}

export async function obrisiEdukaciju(key) {
  firebase
    .database()
    .ref("/kongresiSifre/" + key)
    .remove();
     
}

export async function upisiOrdinaciju(
  adresa,
  mjestoId,
  naziv,
  nosioc,
  sektor,
  telefon,
  vrsta,
  email,

) {
  var d = new Date();
  var n = d.getTime();
  firebase
    .database()
    .ref("/ordinacije/"+n)
    .set({
      adresa: adresa,
      mjestoId: mjestoId,
      naziv: naziv,
      nosioc: nosioc,
      sektor: sektor,
      telefon: telefon,
      vrsta: vrsta,
      id: n,
      email: email,
    });
}

export async function upisiMjesto(
  mjesto,
  ptt,
  broj,
  regija,
 
) {
  var d = new Date();
  var n = d.getTime();
  firebase
    .database()
    .ref("/mjesta/"+n)
    .set({
      mjesto: mjesto,
      ptt: ptt,
      broj: broj,
      regija: regija,
      id: n
    });
}

export async function izmjeniOrdinaciju(
  adresa,
  mjestoId,
  naziv,
  nosioc,
  sektor,
  telefon,
  vrsta,
  email,
  key
) {

  firebase
    .database()
    .ref("/ordinacije/" + key)
    .update({
      adresa: adresa,
      mjestoId: mjestoId,
      naziv: naziv,
      nosioc: nosioc,
      sektor: sektor,
      telefon: telefon,
      vrsta: vrsta,
      email: email,
    });
}

export async function obrisiOrdinaciju(key) {
  firebase
    .database()
    .ref("/ordinacije/" + key)
    .remove();
     
}


export async function upisiSliku(uid,slika) {
  firebase
    .database()
    .ref("/clan/" + uid)
    .update({
      slika: slika,
    });
}

export async function upisiSlikuL(uid,slika) {
  firebase
    .database()
    .ref("/clan/" + uid)
    .update({
      slikaLicence: slika,
    });
}

export async function upisiSlikuDok(gdje,slika) {
  firebase
    .database()
    .ref(gdje)
    .update({
      slika: slika,
    });
}

export async function upisiNapomenu(
  uid,
  napomena,
  datumNapomene
) {
  firebase
    .database()
    .ref("/napomene/" + uid)
    .push({
      napomena: napomena,
      datumNapomene: datumNapomene,
    });
}



export async function obrisiUplatu(uid, kljucUplate) {
  firebase
    .database()
    .ref("/uplateNove/" + uid + "/" + kljucUplate)
    .remove();
     
}

export async function obrisiUplatuS(uid, kljucUplate) {
  firebase
    .database()
    .ref("/clan/" + uid + "/uplate/" + kljucUplate)
    .remove();
     
}



export async function obrisiNapomenu(uid, kljucNapomene) {
  firebase
    .database()
    .ref("/napomene/" + uid + "/" + kljucNapomene)
    .remove();
}

export async function obrisiDogadjajN(uid, kljucDogadjaja) {
  firebase
    .database()
    .ref("/bodoviNovi/" + uid + "/" + kljucDogadjaja)
    .remove();
}

export async function obrisiDogadjajS(uid, kljucDogadjaja) {

  firebase
    .database()
    .ref("/clan/" + uid + "/bodovi/" + kljucDogadjaja)
    .remove();
}

export async function obrisiSlikuEdukacijeN(uid, kljucDogadjaja) {
  firebase
    .database()
    .ref("/bodoviNovi/" + uid + "/" + kljucDogadjaja)
    .update({
      slika: "",
    });
}

export async function obrisiSlikuEdukacijeS(uid, kljucDogadjaja) {

  firebase
    .database()
    .ref("/clan/" + uid + "/bodovi/" + kljucDogadjaja)
    .update({
      slika: "",
    });
}

export async function backupBaze() {
  var snapshot = await firebase.database().once("value");

  return snapshot;
}

export async function lozinke() {
  var snapshot = await firebase.database().ref("/lozinke/").once("value");

  return snapshot;
}


export async function uplateN(uid) {
  var snapshot = await firebase.database().ref("/uplateNove/").once("value");

  return snapshot;
}

export async function uplateNclan(uid) {
  var snapshot = await firebase.database().ref("/uplateNove/"+uid).once("value");

  return snapshot;
}

export async function bodoviN(uid) {
  var snapshot = await firebase.database().ref("/bodoviNovi/").once("value");

  return snapshot;
}

export async function bodoviNclan(uid) {
  var snapshot = await firebase.database().ref("/bodoviNovi/"+uid).once("value");

  return snapshot;
}

export async function kongresiSifre(uid) {
  var snapshot = await firebase.database().ref("/kongresiSifre/").once("value");

  return snapshot;
}




export async function zavrsiUplatu(uid, kljucUplate) {
  firebase
    .database()
    .ref("/uplateLovci/" + uid + "/" + kljucUplate)
    .update({
      statusUplate: "zavrsen",
    });
}



export async function izbrisiClana(uid) {
  firebase
    .database()
    .ref("/clan/" + uid)
    .remove();
}



export async function izmjeniClana(
  adr,
  androidDozvola,
  broj,
  dat_lic,
  disciplinski,             
  ime,
  izvrsni,
  jmbg,
  komisija1,
  komisija2,
  mail,
  mes_id,
  regijaId,
  nadzorni,
  ord_id,
  pol,
  prez,
  skupstina,
  spec_id,
  spec_id2,
  status,
  statusZaposlenje,
  sud,
  tel,
  tit,
  uid
) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      adr: adr,
      androidDozvola: androidDozvola,
      broj: broj,
      dat_lic: dat_lic,
      disciplinski: disciplinski,           
      ime: ime,
      izvrsni: izvrsni,
      jmbg: jmbg,
      komisija1: komisija1,
      komisija2: komisija2,
      mail: mail,
      mes_id: mes_id,
      regijaId: regijaId,
      nadzorni: nadzorni,
      ord_id: ord_id,
      pol: pol,
      prez: prez,
      skupstina: skupstina,
      spec_id: spec_id,
      spec_id2: spec_id2,
      status: status,
      statusZaposlenje: statusZaposlenje,
      sud: sud,
      tel: tel,
      tit: tit
    });
}

export async function izmjeniJednogClana(
              adr,
              mail,
              tel,
              mes_id,
              uid
) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      adr: adr,
      mail: mail,
      mes_id: mes_id,   
      tel: tel    
    });
}

export async function izmjeniSliku(uid) {
  firebase
    .database()
    .ref("/clan/" + uid + "/")
    .update({
      slika: "",
    });
  alert("Izbrisana slika");
}
//export default citaj();
